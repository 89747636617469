import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Container, Box, Card, CardContent } from '@mui/material';
import Navbar from './Navbar'; // Adjust the path to your Navbar component

function BraceletStat() {
  const { qrCode } = useParams(); // Get the qrCode from the URL
  const [braceletData, setBraceletData] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBraceletStats = async () => {
      const token = localStorage.getItem('token'); // Get auth token from local storage

      if (!token) {
        setError('You are not authorized. Please log in.');
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/bracelet/${qrCode}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`, // Attach Bearer token for authorization
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch bracelet data.');

        const data = await response.json();
        setBraceletData(data); // Set bracelet data in state
      } catch (error) {
        console.error('Error fetching bracelet stats:', error);
        setError('Error fetching bracelet stats.');
      }
    };

    fetchBraceletStats();
  }, [qrCode, navigate, apiUrl]);

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: 'linear-gradient(135deg, #F2C4D0, #71BBD9)',
        fontFamily: 'Quicksand, sans-serif',
      }}
    >
      <Navbar />

      <Container
        maxWidth="md"
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '30px',
        }}
      >
        <Card style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%' }}>
          <CardContent>
            {error && (
              <Typography variant="body2" style={{ color: 'red', textAlign: 'center', marginBottom: '20px' }}>
                {error}
              </Typography>
            )}
            {braceletData ? (
              <Box
                style={{
                  padding: '20px',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '12px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Typography variant="h6" style={{ color: '#BF4E83', marginTop: '10px' }}>
                 {braceletData.braceletMessage}
                </Typography>
                <Typography variant="body2" style={{ color: '#4A4A4A', marginTop: '5px' }}>
                    <strong>Number of trades:</strong> {braceletData.tradeCount} 
                </Typography>
                <Typography variant="body2" style={{ color: '#4A4A4A', marginTop: '5px' }}>
                    <strong>Current Location::</strong> {braceletData.lastLocation || 'Unknown'}
                </Typography>
              </Box>
            ) : (
              <Typography variant="h6" style={{ textAlign: 'center', color: '#BF4E83' }}>
                Loading bracelet data...
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default BraceletStat;
