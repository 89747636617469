import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import SignUp from './SignUp';
import Hello from './Hello';
import Bracelet from './Bracelet';
import Feed from './Feed';
import ProtectedRoute from './ProtectedRoute';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword'
import BraceletStat from './BraceletStat';

function App() {
  return (
    <div className="h-full bg-white">
      <Router>
        {/* Assuming you have a Navbar that you want to display on all pages */}
        
        <div className="container mx-auto px-4 py-6">
          <Routes>
            <Route path="/hello" element={<Hello />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password"  element={<ResetPassword />} />
            <Route path="/bracelet/:qrCode" element={<BraceletStat />} />

            <Route
              path="/bracelet"
              element={
                <ProtectedRoute>
                  <Bracelet />
                </ProtectedRoute>
              }
            />
            <Route
              path="/feed"
              element={
                <ProtectedRoute>
                  <Feed />
                </ProtectedRoute>
              }
            />
            {/* Catch-all route for undefined paths */}
            <Route path="*" element={<Navigate to="/hello" />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;