import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import logo from './logo.png'; // Add your friendship bracelet logo image here
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    
    // Navigate to the login page
    navigate('/login');
  };
  return (
    <AppBar position="static" style={{ backgroundColor: '#FFFFFF', boxShadow: 'none' }}>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        {/* Empty div to push the logo to the center */}
        <div></div>

        {/* Centered Logo */}
        <img
          src={logo}
          alt="Friendship Bracelet Logo"
          style={{
            height: '50px',
          }}
        />

        {/* Logout Button */}
        <Button
          color="secondary"
          style={{ textTransform: 'none', fontSize: '16px', color: '#BF4E83' }}
          onClick={handleLogout} 
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
